require('./polyfills.js')
require('./helper/index.js')

window.App = {
    mobileBreak: 768,
    storageItemIds: {
        group: 'kwb-group',
        transition: 'kwb-transition'
    },
    heights: {
        header: 130
    },
    consent: {},
    openModal: function(){},
    onPubAuthor: function(e) {
        e.preventDefault()
        console.log(window)
    }
}

import $ from "cash-dom";
window.$ = $

if (Helper.Device.name !== 'msie') {
    import('petite-vue')
    .then(petiteVue => {
        window.createApp = petiteVue.createApp
    })
}

import Cookies from 'js-cookie'
window.Cookies = Cookies

import Masonry from 'masonry-layout'
window.Masonry = Masonry

import LazyLoad from "vanilla-lazyload";
window.lazyLoad = new LazyLoad();

import {gsap, CSSPlugin, ScrollToPlugin} from 'gsap/all'
gsap.registerPlugin(CSSPlugin, ScrollToPlugin)
window.gsap = gsap

import inView from 'in-view'
window.inView = inView

import Scroll from './helper/Scroll.js'
window.Scroll = Scroll

import Intro from './intro2.js'

window.addEventListener('load', function() {
    const asideHeadHeight = $('aside .aside-head').outerHeight()
    App.heights.asideHead = asideHeadHeight
    $('body').css({"--aside-h": asideHeadHeight + "px"})

    require('./header.js')
    require('./downloads.js')
    require('./cookies.js')
    Intro()
})